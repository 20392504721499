<template>
	<div ref="containerRef">
		<!-- Selection button -->

		<div
			class="relative flex"
			:class="{ 'cursor-not-allowed opacity-50': disabled }"
		>
			<button
				ref="mainButtonRef"
				type="button"
				:id="id"
				class="w-full min-w-0 items-center rounded-md border border-slate-200 bg-white px-2.5 py-1.5 text-left text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus-visible:z-10"
				:class="[
					isError ? 'border-red-300' : 'border-slate-200',
					disabled || isLoading ? 'cursor-not-allowed' : '',
				]"
				@click="!isLoading && !disabled && togglePopover()"
				@keydown.space.prevent="
					!isLoading && !disabled && togglePopover()
				"
				@keydown.enter.prevent="
					!isLoading && !disabled && togglePopover()
				"
				:aria-expanded="isOpen"
				:aria-controls="popoverId"
				:aria-invalid="isError"
				:disabled="disabled || isLoading"
				role="combobox"
			>
				<span
					v-if="isLoading"
					class="flex items-center gap-2 text-slate-400"
				>
					<LoadingSpinnerV2 />
					<span>{{ $s('Core.Label.Loading') }}</span>
				</span>

				<div v-else class="flex items-center justify-between">
					<span class="block h-5 truncate">{{ displayValue }}</span>
					<Icon
						:name="ICON_CHEVRON_UP_DOWN"
						class="h-5 w-5 text-slate-400 group-[.formkit-form-inline]/form:mr-12"
						aria-hidden="true"
					/>
				</div>
			</button>
		</div>

		<!-- Error message -->
		<p v-if="isError && errorMessage" class="mt-1 text-sm text-red-500">
			{{ errorMessage }}
		</p>

		<!-- Teleported popover -->
		<Teleport to="body">
			<div
				v-if="isOpen"
				ref="popoverRef"
				:id="popoverId"
				:style="popoverStyle"
				class="z-40 rounded-lg border border-slate-200 bg-white p-1.5 shadow-lg"
				@keydown.esc="closePopover"
				role="dialog"
				aria-modal="true"
			>
				<!-- Search field -->
				<div class="relative mb-2">
					<div
						class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
					>
						<Icon
							:name="ICON_SEARCH"
							class="h-4 w-4 text-slate-400"
							aria-hidden="true"
						/>
					</div>

					<input
						ref="searchInputRef"
						v-model="searchQuery"
						type="text"
						class="block w-full rounded-md border-0 py-1.5 pl-9 pr-8 text-slate-900 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
						:placeholder="$s('Core.Label.Search')"
						@keydown.down.prevent="navigateOptions('next')"
						@keydown.up.prevent="navigateOptions('prev')"
						@keydown.enter.prevent="selectHighlightedOption"
						@keydown.tab.prevent="handleTab"
						@keydown.esc="closePopover"
						role="searchbox"
						:aria-controls="`${popoverId}-listbox`"
						:aria-activedescendant="highlightedOptionId"
						tabindex="0"
					/>
					<button
						v-if="searchQuery"
						type="button"
						class="absolute inset-y-0 right-0 flex items-center pr-2.5"
						@click.stop="clearSearch"
						:aria-label="$s('Core.Label.ClearSearch')"
					>
						<Icon
							:name="ICON_X"
							class="h-4 w-4 text-slate-500 hover:text-slate-500"
						/>
					</button>
				</div>

				<!-- Options list -->
				<div
					class="ax-scrollbar-thin max-h-64 overflow-y-auto overscroll-contain text-sm"
					:id="`${popoverId}-listbox`"
					role="listbox"
					tabindex="-1"
				>
					<div
						v-if="filteredOptions.length > 0"
						class="flex flex-col gap-px"
					>
						<button
							v-for="(option, index) in filteredOptions"
							:key="option.value"
							type="button"
							ref="optionRefs"
							:id="`${popoverId}-option-${option.value}`"
							class="relative flex w-full cursor-pointer items-center gap-3 rounded-md px-3 py-1.5 text-left outline-none transition-colors"
							:class="[
								isSelected(option.value)
									? 'bg-primary-100 text-slate-900 hover:bg-primary-200 focus:bg-primary-200'
									: '',
								index === highlightedIndex &&
								!isSelected(option.value)
									? 'bg-slate-100 text-slate-900'
									: '',
							]"
							@click="selectOption(option)"
							@mousemove="highlightedIndex = index"
							@mouseleave="highlightedIndex = -1"
							@keydown.enter.prevent="selectOption(option)"
							@keydown.space.prevent="selectOption(option)"
							@keydown.tab.prevent="handleTab"
							@keydown.esc="closePopover"
							@keydown.down.prevent="navigateOptions('next')"
							@keydown.up.prevent="navigateOptions('prev')"
							role="option"
							:aria-selected="isSelected(option.value)"
							tabindex="0"
						>
							<!-- Avatar image -->
							<template v-if="image">
								<Image
									v-if="image(option.attrs)"
									:src="image(option.attrs)"
									:alt="`Avatar for ${option.label}`"
									:height="24"
									:width="24"
									class="h-6 w-6 flex-shrink-0 rounded-full object-cover"
								/>
								<div
									v-else
									:class="[
										'flex h-6 w-6 items-center justify-center rounded-full text-[10px] font-semibold',
										getTextColorClass(option.label),
									]"
								>
									{{ getInitials(option.label) }}
								</div>
							</template>
							<div class="min-w-0 flex-1">
								<div class="flex flex-col">
									<span
										class="truncate font-medium leading-5"
										>{{ option.label }}</span
									>
									<span
										v-if="
											description &&
											description(option.attrs)
										"
										class="truncate text-xs leading-4 text-slate-500"
									>
										{{ description(option.attrs) }}
									</span>
								</div>
							</div>
							<Icon
								v-if="isSelected(option.value)"
								:name="ICON_CHECK"
								class="ml-auto h-4 w-4 flex-shrink-0 text-primary-500"
								aria-hidden="true"
							/>
						</button>
					</div>
					<div v-else class="px-4 py-2 text-sm text-slate-500">
						{{ $s('Core.Info.NoResults') }}
					</div>
				</div>

				<!-- Create new option -->
				<div
					v-if="showCreate"
					class="mt-2 border-t border-slate-200 pt-2"
				>
					<button
						ref="createButtonRef"
						type="button"
						class="flex w-full items-center space-x-1.5 rounded-md px-3 py-1.5 text-left text-sm text-green-500 transition-colors hover:bg-green-50 hover:text-green-600"
						@click="handleCreate"
						@keydown.tab.prevent="handleTab"
						@keydown.up.prevent="navigateOptions('prev')"
						tabindex="0"
					>
						<Icon
							:name="ICON_ADD"
							class="h-4 w-4 text-inherit"
							aria-hidden="true"
						/>
						<span v-if="searchQuery"
							>{{ $s('Core.Label.CreateNew') }}:
							{{ searchQuery }}</span
						>
						<span v-else>{{ $s('Core.Label.CreateNew') }}</span>
					</button>
				</div>
			</div>
		</Teleport>
	</div>
</template>

<script setup lang="ts" generic="TOptionType">
import {
	DropdownItem,
	DropdownItemAttrs,
	DropdownItemInput,
} from '~/types/dropdown';

// Types
export type AutocompleteOption = {
	value: string | number;
	label: string;
	image?: string;
	profilePicture?: string;
};

interface Props {
	id?: string;
	modelValue: string | number | (string | number)[];
	options: DropdownItem<TOptionType>[];
	showCreate?: boolean;
	name?: string;
	multiselect?: boolean;
	isLoading?: boolean;
	isError?: boolean;
	errorMessage?: string;
	disabled?: boolean;
	openOnMount?: boolean;

	image?: (option: DropdownItemAttrs<TOptionType>) => string | undefined;
	description?: (
		option: DropdownItemAttrs<TOptionType>
	) => string | undefined;
}

// Props with runtime validation
const props = withDefaults(defineProps<Props>(), {
	showCreate: false,
	name: 'autocomplete',
	multiselect: false,
	isLoading: false,
	isError: false,
	errorMessage: '',
	disabled: false,
});

// Emits
const emit = defineEmits<{
	(
		e: 'update:modelValue',
		value: null | string | number | (string | number)[]
	): void;
	(e: 'create', value?: string): void;
	(e: 'search', value: string): void;
	(e: 'onPopoverToggle', visible: boolean): void;
}>();

// State
const isOpen = ref(false);
const containerRef = ref<HTMLElement>();
const popoverRef = ref<HTMLElement>();
const searchInputRef = ref<HTMLInputElement>();
const searchQuery = ref('');
const highlightedIndex = ref(-1);
const optionRefs = ref<HTMLButtonElement[]>([]);
const createButtonRef = ref<HTMLButtonElement>();
const mainButtonRef = ref<HTMLButtonElement>();

// Computed
const popoverId = computed(() => `autocomplete-${props.name}-${useId()}`);

const highlightedOptionId = computed(() => {
	if (highlightedIndex.value === -1) return undefined;
	const option = filteredOptions.value[highlightedIndex.value];
	return option ? `${popoverId.value}-option-${option.value}` : undefined;
});

const displayValue = computed(() => {
	if (props.isLoading) return $s('Core.Label.Loading');
	if (props.isError) return $s('Core.Label.ErrorLoadingOptions');

	if (!props.multiselect) {
		return (
			props.options.find((opt) => opt.value === props.modelValue)
				?.label ?? ''
		);
	}

	const selectedValues = Array.isArray(props.modelValue)
		? props.modelValue
		: [];
	if (selectedValues.length === 0) return '';

	const selectedLabels = selectedValues
		.map((id) => props.options.find((opt) => opt.value === id)?.label)
		.filter(Boolean);

	return selectedLabels.join(', ');
});

const filteredOptions = computed(() => {
	const enabledOptions = props.options.filter(
		(option) => !option.attrs.deleted
	);

	if (!searchQuery.value) return enabledOptions;

	const query = searchQuery.value.toLowerCase();
	return enabledOptions.filter((option) => {
		const matchesLabel = option.label.toLowerCase().includes(query);
		const description = props.description?.(option.attrs);
		const matchesDescription = description?.toLowerCase().includes(query);

		return matchesLabel || matchesDescription;
	});
});
// Update popoverStyle to handle scrolling correctly
const popoverStyle = computed(() => {
	if (!containerRef.value) {
		return { top: '0px', left: '0px', width: '0px' };
	}

	const rect = containerRef.value.getBoundingClientRect();
	const windowHeight = window.innerHeight;
	const scrollY = window.scrollY;
	const popoverHeight = 200; // Approximate max height of popover
	const spaceBelow = windowHeight - rect.bottom;
	const spaceAbove = rect.top;
	const minimumSpaceRequired = 400; // Minimum space required above to show popover
	const renderedPopoverHeight =
		popoverRef.value?.getBoundingClientRect().height ?? popoverHeight;

	// Position above only if:
	// 1. Not enough space below AND
	// 2. More space above than below AND
	// 3. At least minimumSpaceRequired pixels available above
	const shouldPositionAbove =
		spaceBelow < popoverHeight &&
		spaceAbove > spaceBelow &&
		spaceAbove >= minimumSpaceRequired;

	// Calculate absolute position including scroll
	const top = shouldPositionAbove
		? rect.top + scrollY - renderedPopoverHeight - 2 // Position above, aligned to bottom of select
		: rect.bottom + scrollY + 2; // Position below

	const position = {
		position: 'absolute' as const,
		top: `${top}px`,
		left: `${rect.left}px`,
		width: `${rect.width}px`,
	};

	return position;
});

// Helper function to check if an option is selected
function isSelected(optionId: string | number): boolean {
	if (props.multiselect) {
		return (
			Array.isArray(props.modelValue) &&
			props.modelValue.includes(optionId)
		);
	}
	return props.modelValue === optionId;
}

// Methods
function updatePosition() {
	// Force reactive update
	nextTick(() => {
		if (!containerRef.value || !isOpen.value) return;

		// Trigger reactivity by accessing the ref
		containerRef.value.getBoundingClientRect();
	});
}

function togglePopover() {
	isOpen.value = !isOpen.value;
	if (isOpen.value) {
		nextTick(() => {
			// Focus search field when opening dropdown
			searchInputRef.value?.focus();
			highlightedIndex.value = -1;
		});
	}
}

function closePopover() {
	isOpen.value = false;
	searchQuery.value = '';
	highlightedIndex.value = -1;

	setTimeout(() => {
		mainButtonRef.value?.focus();
	}, 50);
}

// Updated select function to handle both single and multiselect
function selectOption(option: DropdownItem<TOptionType>) {
	if (props.multiselect) {
		const currentValue = Array.isArray(props.modelValue)
			? props.modelValue
			: [];
		const newValue = currentValue.includes(option.value)
			? currentValue.filter((id) => id !== option.value)
			: [...currentValue, option.value];

		emit('update:modelValue', newValue);
		// Don't close popover in multiselect mode
		searchQuery.value = '';
		highlightedIndex.value = -1;
		searchInputRef.value?.focus();
	} else {
		const newValue =
			props.modelValue === option.value ? null : option.value;
		emit('update:modelValue', newValue);

		// Close popover first
		isOpen.value = false;
		searchQuery.value = '';
		highlightedIndex.value = -1;

		setTimeout(() => {
			mainButtonRef.value?.focus();
		}, 50);
	}
}

function handleCreate() {
	emit('create', searchQuery.value);
	closePopover();
}

function navigateOptions(direction: 'next' | 'prev') {
	const maxIndex = filteredOptions.value.length - 1;
	if (maxIndex < 0) return;

	if (direction === 'next') {
		if (highlightedIndex.value === maxIndex) {
			// At last option, focus create button
			highlightedIndex.value = -1;
			nextTick(() => {
				createButtonRef.value?.focus();
			});
		} else {
			highlightedIndex.value =
				highlightedIndex.value >= maxIndex
					? 0
					: highlightedIndex.value + 1;
			focusOption(highlightedIndex.value);
		}
	} else {
		if (document.activeElement === createButtonRef.value) {
			// From create button, go to last option
			highlightedIndex.value = maxIndex;
			focusOption(maxIndex);
		} else {
			highlightedIndex.value =
				highlightedIndex.value <= 0
					? maxIndex
					: highlightedIndex.value - 1;
			focusOption(highlightedIndex.value);
		}
	}
}

function selectHighlightedOption() {
	if (highlightedIndex.value === -1) return;
	const option = filteredOptions.value[highlightedIndex.value];
	if (option) {
		selectOption(option);
	}
}

function handleTab(event: KeyboardEvent) {
	// Prevent default tab behavior
	event.preventDefault();

	const activeElement = document.activeElement;
	const isShiftTab = event.shiftKey;

	// If coming from search field
	if (activeElement === searchInputRef.value) {
		if (isShiftTab) {
			// Shift+Tab from search goes to create button if it exists
			if (props.showCreate) {
				createButtonRef.value?.focus();
			}
		} else {
			// Tab from search goes to create button if it exists
			if (props.showCreate) {
				createButtonRef.value?.focus();
			}
		}
		return;
	}

	// If coming from create button
	if (activeElement === createButtonRef.value) {
		if (isShiftTab) {
			// Shift+Tab from create goes to search
			searchInputRef.value?.focus();
		} else {
			// Tab from create goes to search (circular)
			searchInputRef.value?.focus();
		}
		return;
	}

	// For any other element, focus search if it exists
	searchInputRef.value?.focus();
}

function focusOption(index: number) {
	nextTick(() => {
		optionRefs.value[index]?.focus();
	});
}

function clearSearch(event?: Event) {
	event?.preventDefault();
	event?.stopPropagation();
	searchQuery.value = '';
	highlightedIndex.value = -1;
	nextTick(() => {
		searchInputRef.value?.focus();
	});
}

// Lifecycle
onMounted(() => {
	// Initial position
	updatePosition();

	// Add event listeners with passive option for better performance
	window.addEventListener('scroll', updatePosition, { passive: true });
	window.addEventListener('resize', updatePosition, { passive: true });

	// Add scroll listeners to all parent scrollable elements
	let parent = containerRef.value?.parentElement;
	while (parent) {
		if (isScrollable(parent)) {
			parent.addEventListener('scroll', updatePosition, {
				passive: true,
			});
		}
		parent = parent.parentElement;
	}

	nextTick(() => {
		searchInputRef.value?.focus();
	});

	// Click outside handler
	window.addEventListener('click', (e: MouseEvent) => {
		if (!isOpen.value) return;

		const target = e.target as Node;
		const clickedContainer = containerRef.value?.contains(target);
		const clickedPopover = popoverRef.value?.contains(target);

		if (!clickedContainer && !clickedPopover) {
			closePopover();
		}
	});

	if (props.openOnMount) {
		setTimeout(() => {
			togglePopover();
		});
	}
});

onBeforeUnmount(() => {
	// Remove all event listeners
	window.removeEventListener('scroll', updatePosition);
	window.removeEventListener('resize', updatePosition);

	// Remove scroll listeners from parent elements
	let parent = containerRef.value?.parentElement;
	while (parent) {
		if (isScrollable(parent)) {
			parent.removeEventListener('scroll', updatePosition);
		}
		parent = parent.parentElement;
	}
});

// Helper function to check if an element is scrollable
function isScrollable(element: HTMLElement): boolean {
	const hasScrollableContent = element.scrollHeight > element.clientHeight;
	const overflowYStyle = window.getComputedStyle(element).overflowY;
	return (
		hasScrollableContent &&
		(overflowYStyle === 'auto' || overflowYStyle === 'scroll')
	);
}

// Add debounced search emit
const debouncedSearch = useDebounceFn((query: string) => {
	emit('search', query);
}, 300);

function getInitials(fullName: string) {
	return $toInitials(fullName, { maxLength: 2 });
}

function getTextColorClass(fullName: string) {
	const initials = getInitials(fullName);
	return $textToColor(initials);
}

// Update search handler
watch(searchQuery, (newQuery) => {
	debouncedSearch(newQuery);
});

// Add error handling
watch(
	() => props.isError,
	(newValue) => {
		if (newValue) {
			closePopover();
		}
	}
);

// Add disabled handling
watch(
	() => props.disabled,
	(newValue) => {
		if (newValue) {
			closePopover();
		}
	}
);

watch(isOpen, () => {
	emit('onPopoverToggle', isOpen.value);
});

defineExpose({
	togglePopover,
});
</script>
